import React, { useRef } from "react";
import ReactMarkdown from "react-markdown";
import Svg from "../utils/Svg.jsx";
import ws from "../lib/backend.jsx";
import "../styles/style.css";

const Messaggio = ({ type, content, remainingCredits, doneWriting }) => {
  const likeRef = useRef(null);
  const dislikeRef = useRef(null);

  const functLike = (e) => {
    e.preventDefault();
    // console.log(remainingCredits);
    dislikeRef.current.children[0].classList.remove("clicked");
    likeRef.current.children[0].classList.add("clicked");
    // console.log(ws);
    const obj = {
      id: "send_feedback",
      payload: { qa_id: remainingCredits, text: "like" },
    };
    ws.send(JSON.stringify(obj));
  };

  const functDislike = (e) => {
    e.preventDefault();
    // console.log(remainingCredits);
    dislikeRef.current.children[0].classList.add("clicked");
    likeRef.current.children[0].classList.remove("clicked");
    // console.log(ws);
    const obj = {
      id: "send_feedback",
      payload: { qa_id: remainingCredits, text: "dislike" },
    };
    ws.send(JSON.stringify(obj));
  };

  return (
    <message-cont>
      <ReactMarkdown className={type}>{content}</ReactMarkdown>
      {type === "ai-message" && doneWriting && (
        <div className="feedback-container">
          <button ref={likeRef} onClick={functLike}>
            <Svg icon="like" stroke="#888888" />
          </button>
          <button ref={dislikeRef} onClick={functDislike}>
            <Svg icon="dislike" stroke="#888888" />
          </button>
        </div>
      )}
    </message-cont>
  );
};

export default Messaggio;
