import { useEffect, useCallback } from "react";
import { debounce } from "../utils/debounce";

const useScrollCheck = (
  messageSectionRef,
  setisInTopEightyPercent,
  threshold
) => {
  const updateScrollPosition = useCallback(() => {
    const container = messageSectionRef.current;
    if (!container) return;

    const { scrollTop, clientHeight, scrollHeight } = container;
    const scrollPercentage = (scrollTop + clientHeight) / scrollHeight;

    // Check if user is in the first x% of the scrollable area
    const isInTopEightyPercent =
      scrollHeight > clientHeight && scrollPercentage < threshold;

    setisInTopEightyPercent(isInTopEightyPercent);
  }, [messageSectionRef, setisInTopEightyPercent, threshold]);

  useEffect(() => {
    const container = messageSectionRef.current;
    if (!container) return;

    const debouncedUpdateScrollPosition = debounce(updateScrollPosition, 50);

    container.addEventListener("scroll", debouncedUpdateScrollPosition);

    updateScrollPosition();

    return () => {
      container.removeEventListener("scroll", debouncedUpdateScrollPosition);
    };
  }, [messageSectionRef, updateScrollPosition]);
};

export default useScrollCheck;
