import React from "react";
import { toast } from "react-toastify";
import Svg from "../utils/Svg";
import logo_img from "../../src/assets/images/logo_saep.svg";

function copyChat(messages) {
  if (messages.length === 0) {
    toast("Chat vuota!", { icon: () => <Svg icon="alert" stroke="#000" /> });
    return;
  }

  const chatCopied = messages
    .map((item) =>
      item.type === "ai-message"
        ? `[ELi]: ${item.content}`
        : `[User]: ${item.content}`
    )
    .join("\n");

  navigator.clipboard.writeText(chatCopied);
  toast("Testo copiato!", { icon: () => <Svg icon="ok" stroke="#568fdb" /> });
}

// Pulsante Generico
const Button = ({ title, onClick, className = "", children }) => (
  <div
    title={title}
    className={`button ${className}`} // Combina la classe base con quella specifica
    onClick={onClick}
  >
    {children}
  </div>
);

const NavBar = ({ messages, modalRefreshRef, modalLogoutRef }) => {
  const Logo = () => (
    <div className="main-logo-cont">
      <img title="Esci da ELi" alt="" src={logo_img} onClick={handleLogout} />
    </div>
  );

  const handleLogout = (e) => {
    if (!modalLogoutRef.current) return;
    e.preventDefault();
    modalLogoutRef.current.showModal();
  };

  const handleRefresh = (e) => {
    if (!modalRefreshRef.current) return;
    e.preventDefault();
    modalRefreshRef.current.showModal();
  };

  return (
    <nav>
      <Logo />

      <div className="buttons-cont">
        <Button
          title="Copia la chat intera"
          onClick={() => copyChat(messages)}
          className="copy-chat-button"
        >
          <Svg icon="copy" stroke="#568fdb" />
        </Button>
        <Button
          title="Ricarica la pagina"
          onClick={handleRefresh}
          className="refresh-button"
        >
          <Svg icon="refresh" stroke="#568fdb" />
        </Button>
        <Button
          title="Esci da ELi"
          onClick={handleLogout}
          className="exit-button"
        >
          <Svg icon="exit" stroke="#568fdb" />
        </Button>
      </div>
    </nav>
  );
};

export default NavBar;
