import Svg from "../../utils/Svg";
import "../../styles/style.css";
import { forwardRef } from "react";

const logout = () => {
  window.location.href = "https://www.saep.it/";
};

const ModalLogout = forwardRef((_, ref) => {
  const closeModal = () => {
    if (ref.current) {
      ref.current.close();
    }
  };

  return (
    <dialog ref={ref} className="modal-logout">
      <form method="dialog">
        <div className="titleCont">
          <p className="titleDial">Abbandona questa pagina</p>
          <button type="button" onClick={closeModal}>
            <Svg icon="cross" stroke="#000" />
          </button>
        </div>
        <div className="divider"></div>
        <div className="txtDialCont">
          <p className="txtDial">
            Cliccando sul tasto "Esci" verrai reindirizzato al sito di SAEP. Sei
            sicuro di voler continuare?
          </p>
          <div>
            <button type="button" className="button-confirm" onClick={logout}>
              Esci
            </button>
          </div>
        </div>
      </form>
    </dialog>
  );
});

export default ModalLogout;
