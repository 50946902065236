// prod
const SERVER_IP_STRING = "https://eli.saep-ict.it/ws/";

// test
// const SERVER_IP_STRING = "https://eli-test.saep-ict.it/ws/";

// local
// const SERVER_IP_STRING = "http://localhost:8000/ws/";

// local con FastAPI
// const SERVER_IP_STRING = "ws://localhost/ws/";

const ws = new WebSocket(SERVER_IP_STRING);

export default ws;
