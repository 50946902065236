import React, { useState, useRef, useEffect, useCallback } from "react";
import { Slide, ToastContainer, toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import ws from "../lib/backend.jsx";
import "../styles/style.css";
import "react-toastify/dist/ReactToastify.css";
import Messaggio from "../components/Message";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import InputSection from "../components/InputSection";
import Svg from "../utils/Svg";
import { ModalLogout, ModalRefresh } from "../components/modals";

const Home = () => {
  const [remainingCredits, setRemainingCredits] = useState(5);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [isReplying, setIsReplying] = useState(false); // Variaile che uso per capire se sta ancora rispondendo e non permetto di inviare altre richieste
  const [isThinking, setIsThinking] = useState(false);
  const [refreshMessage, setRefreshMessage] = useState(
    "Proseguendo ripristinerai i crediti ma cancellerai la chat. Sei sicuro di voler ricarica la pagina?"
  );
  const [refreshTitle, setRefreshTitle] = useState("Ricarica la pagina");

  const stringaRef = useRef("");
  const messagesEndRef = useRef(null); // Variabile del messaggio per lo scroll (riferimento)
  const aiMessageIndexRef = useRef(null); // Track the index of the current AI message being updated
  const messageSectionRef = useRef(null);
  const modalRefreshRef = useRef(null);
  const modalLogoutRef = useRef(null);

  const isSmall = useMediaQuery({ query: `(max-width: 1024px)` });

  const sendMessage = useCallback(
    (pressType, message) => {
      // Helper function to handle interrupt scenarios
      const handleInterrupt = (pressType) => {
        if (pressType === "click") {
          const obj = { id: "stop_response" };
          ws.send(JSON.stringify(obj));
          setIsReplying(false);
          setIsThinking(false);
        }
      };

      // Helper function to initiate message sending
      const performSendMessage = () => {
        setIsReplying(true);
        setIsThinking(true);

        // Append user message to chat
        setMessages((messages) => [
          ...messages,
          { content: message.trim(), type: "human-message" },
        ]);

        // Send message via WebSocket
        ws.send(
          JSON.stringify({
            id: "send_message",
            payload: message.trim(),
          })
        );

        // Clear input message div
        setInputMessage("");
      };

      // Check for ongoing interruptions
      if (isReplying || isThinking) {
        handleInterrupt(pressType);
        return;
      }

      // Show modal if no credits remain
      if (remainingCredits <= 0) {
        setRefreshTitle("Crediti esauriti");
        setRefreshMessage(
          "I crediti a tua disposizione sono terminati. Ricaricando la pagina i crediti verranno ricaricati e la chat verra svuotata."
        );
        modalRefreshRef.current.showModal();
        return;
      }

      // Validate input and proceed if valid
      if (message.trim() === "") return;

      // Proceed with sending the message
      performSendMessage();
    },
    [isReplying, isThinking, remainingCredits]
  );

  const sendSuggestionMessage = (e) => {
    const message = e.target.innerText.trim();
    if (!message) return;
    sendMessage("click", message);
  };

  useEffect(() => {
    if (!messagesEndRef.current) return;
    messagesEndRef.current.scrollIntoView({ behavior: "auto" });
  }, [messages]);

  useEffect(() => {
    if (!isThinking) return;
    setMessages((messages) => {
      aiMessageIndexRef.current = messages.length; // the index of the last message to be appended.
      return [...messages, { content: "Sto pensando...", type: "ai-message" }];
    });
  }, [isThinking]);

  // To stream the response
  useEffect(() => {
    ws.onopen = () => {
      console.log("WebSocket connection established");
    };

    ws.onclose = () => {
      setRefreshTitle("Sessione scaduta");
      setRefreshMessage(
        "La sessione corrente è scaduta, ricaricare la pagina."
      );
      modalRefreshRef.current.showModal();
    };

    // handling the chat response
    function handleChatResponse(data) {
      const handleFirstMessage = () => {
        setRemainingCredits(data.credits);
        setMessages((messages) => messages.slice(0, -1)); // Remove the last message (Sto pensando...)
        stringaRef.current = data.message.content; // Reset stringaRef when the first part of the message is received.
        setMessages((messages) => {
          aiMessageIndexRef.current = messages.length; // the index of the last message to be appended.
          return [
            ...messages,
            {
              content: stringaRef.current,
              type: "ai-message",
              remainingCredits: data.credits,
              doneWriting: false,
            },
          ];
        });
      };

      const handleLastMessage = () => {
        stringaRef.current += data.message.content;
        setMessages((messages) => {
          const updatedMessages = [...messages];
          updatedMessages[aiMessageIndexRef.current].content =
            stringaRef.current;
          updatedMessages[messages.length - 1] = {
            ...updatedMessages[messages.length - 1],
            doneWriting: true,
          };
          return updatedMessages;
        });
        setIsReplying(false);
        // Show toast if credits are run out
        if (data.credits === 0) {
          toast("Hai terminato i crediti a tua disposizione.", {
            icon: () => <Svg icon="alert" />,
          });
        }
      };

      const handleNormalMessage = () => {
        setIsThinking(false);
        stringaRef.current += data.message.content; // Append the content to stringaRef.
        setMessages((messages) => {
          const updatedMessages = [...messages];
          updatedMessages[aiMessageIndexRef.current].content =
            stringaRef.current;
          return updatedMessages;
        });
      };

      // Validate data
      if (!data?.message || typeof data.message.content !== "string") return;

      if (data.message.first) {
        handleFirstMessage();
        return;
      }

      if (data.message.last) {
        handleLastMessage();
        return;
      }

      handleNormalMessage();
    }

    // handling the error event
    function errorMessage(data) {
      setMessages((messages) => messages.slice(0, -1)); // Remove the last message (Sto pensando...)
      setIsReplying(false);
      setIsThinking(false);
      toast("Errore: " + data.message, {
        icon: () => <Svg icon="exclamation" />,
      });
    }

    ws.onmessage = (response) => {
      const data = JSON.parse(response.data);
      switch (data.id) {
        case "chat_response":
          handleChatResponse(data.payload);
          break;
        case "error":
          errorMessage(data.payload);
          break;
        default:
          break;
      }
    };
  }, []);

  return (
    <>
      <whole-page>
        <NavBar
          messages={messages}
          modalRefreshRef={modalRefreshRef}
          modalLogoutRef={modalLogoutRef}
        />
        <chat-section>
          <messages-section ref={messageSectionRef}>
            {messages.length > 0 ? (
              messages.map((item, index) => {
                return (
                  <Messaggio
                    key={index}
                    type={item.type}
                    content={item.content}
                    remainingCredits={item.remainingCredits}
                    doneWriting={item.doneWriting}
                  />
                );
              })
            ) : (
              <div className="pageEmptyCont">
                <div className="introCont">
                  <p className="introTitle"> Ciao, sono ELi </p>
                  <p className="introSubTitle">
                    {" "}
                    Oggi ti suggerisco questi argomenti{" "}
                  </p>
                </div>

                <div className="suggestionsCont">
                  <div
                    onClick={sendSuggestionMessage}
                    className="suggestionsItem"
                  >
                    SAEP offre servizi di Business Intelligence?
                  </div>
                  <div
                    onClick={sendSuggestionMessage}
                    className="suggestionsItem"
                  >
                    Parlami della migrazione ACG nel vostro ambito.
                  </div>
                  {!isSmall && (
                    <div
                      onClick={sendSuggestionMessage}
                      className="suggestionsItem"
                    >
                      Quali vantaggi porta avere un unico software integrato?
                    </div>
                  )}
                  {!isSmall && (
                    <div
                      onClick={sendSuggestionMessage}
                      className="suggestionsItem"
                    >
                      Qual è il valore aggiunto di adottare l'ERP SAEP?
                    </div>
                  )}
                </div>
              </div>
            )}
            <div ref={messagesEndRef} className="referenceBottomChat" />
          </messages-section>
          <InputSection
            inputMessage={inputMessage}
            setInputMessage={setInputMessage}
            remainingCredits={remainingCredits}
            sendMessage={sendMessage}
            isReplying={isReplying}
            messagesEndRef={messagesEndRef}
            messageSectionRef={messageSectionRef}
          />
        </chat-section>
        <Footer />
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          transition={Slide}
        />
      </whole-page>
      <ModalLogout ref={modalLogoutRef} />
      <ModalRefresh
        ref={modalRefreshRef}
        title={refreshTitle}
        message={refreshMessage}
      />
    </>
  );
};

export default Home;
