import React, { useState } from "react";
import Svg from "../utils/Svg";
import useScrollCheck from "../hooks/useScrollCheck";
import "../styles/style.css";

const InputSection = ({
  inputMessage,
  setInputMessage,
  remainingCredits,
  sendMessage,
  isReplying,
  messagesEndRef,
  messageSectionRef,
}) => {
  const [isInTopEightyPercent, setisInTopEightyPercent] = useState(false);

  useScrollCheck(messageSectionRef, setisInTopEightyPercent, 0.8);

  const handleScrollToBottom = (e) => {
    e.preventDefault();
    if (!messagesEndRef.current) return;
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleEnter = (e) => {
    if (!(e.key === "Enter" && !e.shiftKey)) return;
    e.preventDefault();
    sendMessage("enter", inputMessage);
  };

  const ScrollToBottom = ({ isVisible }) => {
    if (!isVisible) return null;

    return (
      <button
        className="go-to-bottom-button"
        onClick={handleScrollToBottom}
        aria-label="Scroll to bottom"
      >
        <Svg icon="arrow_down" />
      </button>
    );
  };

  return (
    <input-section>
      <ScrollToBottom isVisible={isInTopEightyPercent} />
      <input-box>
        <div className="textarea-cont">
          <textarea
            name=""
            id=""
            placeholder="Scrivi un messaggio a ELi..."
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyDown={handleEnter}
            aria-label="Message input field"
          />
        </div>
        <div className="buttons">
          <div title="Crediti rimanenti" className="crediti-cont">
            🪙 {remainingCredits}{" "}
          </div>
          <div> | </div>
          <div
            className="send-button"
            onClick={(e) => {
              e.preventDefault();
              sendMessage("click", inputMessage);
            }}
          >
            {isReplying ? (
              <Svg icon="crossInCircle" className="size-6-stop" />
            ) : (
              <Svg icon="send" />
            )}
          </div>
        </div>
      </input-box>
    </input-section>
  );
};

export default InputSection;
