import React from "react";

const footerLinks = [
  {
    href: "https://www.saep.it/faq/",
    label: "Info",
    title: "Frequently Asked Questions",
  },
  {
    href: "https://www.iubenda.com/privacy-policy/50130093",
    label: "Privacy",
    title: "Privacy Policy",
  },
  {
    href: "https://www.saep.it/contatti/",
    label: "Contatti",
    title: "Contact Us",
  },
];

const Footer = () => {
  return (
    <footer>
      {footerLinks.map(({ href, label, title }, index) => (
        <a
          key={index}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          title={title}
        >
          {label}
        </a>
      ))}
    </footer>
  );
};

export default Footer;
