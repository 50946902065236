import React, { forwardRef } from "react";
import Svg from "../../utils/Svg";
import "../../styles/style.css";

const refresh = () => {
  window.location.reload();
};

const ModalRefresh = forwardRef(({ title, message }, ref) => {
  const closeModal = () => {
    if (ref.current) {
      ref.current.close();
    }
  };

  return (
    <dialog ref={ref} className="modal-refresh">
      <form method="dialog">
        <div className="titleCont">
          <p className="titleDial">{title}</p>
          <button type="button" onClick={closeModal}>
            <Svg icon="cross" stroke="#000" />
          </button>
        </div>
        <div className="divider"></div>
        <div className="txtDialCont">
          <p className="txtDial">{message}</p>
          <div>
            <button type="button" className="button-confirm" onClick={refresh}>
              Ricarica
            </button>
          </div>
        </div>
      </form>
    </dialog>
  );
});

export default ModalRefresh;
